<template>
  <div>
    <Header theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="article-wrap">
      <div class="max-center">
        <h1>法律声明</h1>
        <p>为保证重庆云昇新控智能科技有限公司网站（简称“云昇新控”）及权利人的合法权益，特声明如下：</p>
        <p>一、云昇新控作为重庆云昇新控智能科技有限公司的官方网站，权威发布重庆云昇新控智能科技有限公司的有关信息。</p>
        <p>
          二、重庆云昇新控智能科技有限公司独立拥有或与相关内容提供者共同拥有本网站内相关内容的版权。拟使用本网站的文字、图片、视/音频及其它信息资料的，需事先与本网站联系并取得明确的书面许可。未经许可，任何媒体、网站、商业机构及个人不得对本网站的内容进行复制或在非重庆云昇新控智能科技有限公司所属的服务器上做镜像，不得对本网站的全部或部分页面、内容做商业性的链接和利用，也不得歪曲和篡改本网站所发布的内容。
        </p>
        <p>三、本网站转载的信息，已尽力取得版权人的使用许可，如有遗漏，请与本网站联系。本网站转载的相关文章、资料中表述或暗示的观点不代表重庆云昇新控智能科技有限公司。</p>
        <p>四、本网站不能控制或保证与之相链接的各个网站信息的准确性、及时性和完整性。</p>
        <p>本网站《网站声明》的修改及更新权均属于重庆云昇新控智能科技有限公司网站。</p>
        <p>联系方式：023-6780 9905</p>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import BackTop from '../../components/BackTop'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '法律声明,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控法律声明,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less">
.article-wrap {
  text-align: left;
  font-family: Microsoft YaHei;

  .max-center {
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    padding: 2% 0 5%;
  }

  h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #1890FF;
    line-height: 3;
  }

  p {
    text-indent: 2em;
    line-height: 2;
  }
}
</style>
